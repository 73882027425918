import React from "react"
import PropTypes from "prop-types"

const Layout = ({ children }) => {
  return (
    <>
      <main>{children}</main>
      <footer>
        <div className="content">
          <p>&copy; Copyright {new Date().getFullYear()}</p>
          <p>Zubhav Ltd</p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
