import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../index.scss"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
      cvImage: file(relativePath: { eq: "cv.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      avatarImage: file(relativePath: { eq: "avatar.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      landingIcons: allContentfulLandingIcon(sort: {fields: order}) {
        edges {
          node {
            title
            link
            file {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const { landingIcons, site } = data
  const { title, description } = site.siteMetadata
  const { edges: icons } = landingIcons

  return (
    <Layout>
      <SEO title={title} />
      <a
        href="mailto:subhavg@icloud.com?subject=Request for CV&body=Hi Subhav, I'm ____.%0D%0DCould you please send me a copy of your CV?"
        title="Request my CV"
      >
        <Img className="cv" fluid={data.cvImage.childImageSharp.fluid} />
      </a>

      <Img className="avatar" fluid={data.avatarImage.childImageSharp.fluid} />

      <h1>{title}</h1>
      <h2>{description}</h2>

      <ul className="icons">
        {icons.map((icon, idx) => {
          const { node: { link, title, file: { file: { url: iconLink } } } } = icon
          const href = "https:" + iconLink

          return (
            <li key={`${title.toLowerCase()}-${idx}`}>
              <OutboundLink
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                title={title}
              >
                <img src={href} alt={title} className={title.toLowerCase()}/>
              </OutboundLink>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default IndexPage
